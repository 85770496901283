<template>
  <div>
    <h1 class="mb-0">Sign Up</h1>
    <p>Enter your email address and password to access admin panel.</p>
    <b-form role="form" @submit.prevent="onSubmit">
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input
          type="text"
          class="form-control mb-0"
          id="firstName"
          placeholder="First Name"
          :rules="{ required: true }"
          v-model="model.contactPersonFirstName"
        />
      </div>
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input
          type="text"
          class="form-control mb-0"
          id="lastName"
          placeholder="Last Name"
          :rules="{ required: true }"
          v-model="model.contactPersonLastName"
        />
      </div>
      <div class="form-group">
        <label for="Email">Email address</label>
        <input
          type="email"
          class="form-control mb-0"
          placeholder="Email"
          name="Email"
          :rules="{ required: true, email: true }"
          v-model="model.emailAddress"
        />
      </div>
      <div class="form-group">
        <label for="Password">Password</label>
        <input
          type="password"
          class="form-control mb-0"
          placeholder="password"
          name="Password"
          :rules="{ required: true, min: 6 }"
          v-model="model.password"
        />
      </div>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customCheck1"
          />
          <label class="custom-control-label" for="customCheck1"
            >I accept
            <a href="https://evangelize.azurewebsites.net/terms"
              >Terms and Conditions</a
            ></label
          >
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary mt-4">
            Create account
          </button>
        </div>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2"
          >Already Have Account ?
          <router-link :to="{ name: 'login' }">Log In</router-link></span
        >
        <!-- <ul class="iq-social-media">
          <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
          <li><a href="#"><i class="ri-twitter-line"></i></a></li>
          <li><a href="#"><i class="ri-instagram-line"></i></a></li>
        </ul> -->
      </div>
    </b-form>
  </div>
</template>
<script>
export default {
  name: "SignUp1",
  data() {
    return {
      model: {
        contactPersonFirstName: "",
        contactPersonLastName: "",
        emailAddress: "",
        password: "",
        agree: false,
      },
    };
  },
  methods: {
    onSubmit(e) {
      this.loading = true;
      this.submitted = true;
      const { dispatch } = this.$store;
      let self = this;

      if (this.model.emailAddress && this.model.password) {
        dispatch("authentication/register", self.model).then(function (evt) {
          if (evt) {
            self.$router.push("/");
          } else {
            self.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: '<a href="">Please check your username and password.</a>',
            });
          }
          self.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
button.btn.btn-primary {
  background: #50b5ff !important;
  border-color: #50b5ff !important;
}
</style>
